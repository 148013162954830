import React from "react"
import { graphql } from "gatsby"
import { Grid, Box, Container } from "@material-ui/core"
import _ from "lodash"

import LayoutComponent from "../components/layout.component"
import ArticleCardComponent from "../components/articlecard.component"

const Latest = ({ data, location }) => {
  return (
    <LayoutComponent>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container spacing={3} direction="row">
            {_.map(data.allStrapiArticle.edges, ({ node }, i) => {
              return (
                <Grid item xs={12} md={3} key={i}>
                  <ArticleCardComponent node={node} />
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}

export default Latest

export const latestQuery = graphql`
  query LatestQuery {
    allStrapiArticle(sort: { fields: id, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          content
          excerpt
          createdAt
          picture {
            publicURL
          }
          tags {
            id
            name
            slug
          }
          category {
            id
            name
            slug
          }
        }
      }
    }
  }
`
